/* .pagination-button {
    width: 15px;
    text-align: center;
    padding: 0 16px;
    height: 100%;

} */

.pagination-button::after, .pagination-dots::after {
content: "";
position: absolute;
border-right: 2px solid #4C34AD;
height: 100%;
top: 0;
left: 0;

/* display: inline-block; */
}
.pagination-button-one::before {
content: "";
position: relative;
border-left: 2px solid black;
height: 100%;
top: 0;
/* right: 16px; */
}
* {
    box-sizing: border-box;
}
.DropDownBorder {
    border-bottom: 2px solid rgba(27, 27, 27, 0.1);
    outline: none;
}

.DropDownBorder:focus-within {
    outline: 1px solid black;
}

.CarTilesBorder {
    border-bottom: 2px solid #ebebeb;
}

.gradientAddvehicleColor {
    background: transparent
        linear-gradient(114deg, #ffffff 0%, #f6f6ff 14%, #d6d8ff 100%) 0% 0%
        no-repeat padding-box;
    /* background-image: url('./Assets//addNewVehicle\ \(1\).png'); */
}

.borderRad {
    border-radius: 18px 18px 18px 18px / 34px 34px 34px 34px;
}

.element-with-drop-shadow {
    /* Set your desired background color */
    /* Set your desired margin */
    box-shadow: 0px -53px 43px -5px #d6d8ff, 0px -3px 8px -8px #d6d8ff;
    /* The box-shadow property consists of two shadows:
       - First shadow: 0px 8px 8px -8px rgba(0, 0, 0, 0.75) (top shadow)
       - Second shadow: 0px -8px 8px -8px rgba(0, 0, 0, 0.75) (bottom shadow)
       You can adjust the values according to your preferences.
    */
}

/* .dx-scheduler .dx-scheduler-date-table-cell {
    height: 100px; 
  }
  
  .dx-scheduler .dx-scheduler-date-table-cell .dx-scheduler-date-table-cell-text {
    font-size: 14px; 
  } */
  
/* .dx-scheduler-views-wrapper .dx-scheduler-date-table-month .dx-scheduler-date-table-cell {
    height: 200px; 
}

.dx-scheduler-work-space-month{
  height: 200px; 
}


.dx-scheduler-views-wrapper .dx-scheduler-date-table-month .dx-scheduler-date-table-cell .dx-scheduler-date-table-cell-text {
    font-size: 14px;
}  */


/* Target month view specifically */
.dx-scheduler-work-space-month .dx-scheduler-date-table-cell {
  height: 150px !important; /* Adjust the height as needed */
}

/* Target the left and right navigation buttons */
.dx-scheduler-navigator-previous,
.dx-scheduler-navigator-next {
  font-size: 24px !important; 
  width: 40px !important; 
  height: 40px !important; 
}

.dx-scheduler-navigator-previous .dx-icon,
.dx-scheduler-navigator-next .dx-icon {
  font-size: 24px !important; 
}

/* Increase the width of the date table cells */
.dx-scheduler-date-table-cell {
  width: 200px;  /* Adjust this value as per your requirement */
}

/* Increase the width of the header cells (Monday-Sunday) */
.dx-scheduler-header-panel-cell {
  width: 200px;  /* Ensure the width matches the date table cells */
}

/* Increase the width of the all-day panel cells */
.dx-scheduler-all-day-table-cell {
  width: 200px;  /* Ensure this matches the width of the regular cells */
}

/* Adjust the width of the all-day panel if necessary */
.dx-scheduler-all-day-panel {
  width: 200px;
}



/* Target the scheduled appointments in the month view */
/* .dx-scheduler-work-space-month .dx-scheduler-appointment {
  margin-bottom: 5px !important;  
  padding: 5px; 
  box-sizing: border-box; 
} */

/* Handle overflow to show all appointments without clipping */
/* .dx-scheduler-work-space-month .dx-scheduler-date-table-cell {
  overflow: visible !important;
} */




.Instructor_Profile {
    @apply aspect-square  w-1/2 max-w-[165px];
}

.Teacher_PreviewContainer {
    @apply flex w-full
                items-center                   gap-5 
                sm:w-auto    sm:items-start
                md:w-1/2 md:justify-start  lg:w-[500px];
}
/* ----------------- */
.Instructor_ImagePreview {
    @apply aspect-square  w-1/2 max-w-[165px];
}
.Instructor_BrowseBtn {
    @apply bg-[#F0F0F7] text-6xs sm:text-5xs lg:text-4xs;
}
.Instructor_PreviewContainer {
    @apply flex w-full
                items-center                   gap-5 
                sm:w-auto    sm:items-start
                md:w-1/2  md:justify-start;
}
/* .Instructor-PreviewContainer-Container {
    @apply flex w-full max-w-[400px]
             flex-col        gap-4   sm:flex-row              md:w-[30%] 
             md:flex-col                     md:gap-2 
             lg:w-[25%] lg:gap-3                      xl:w-[20%]        2xl:flex-row    2xl:gap-5;
} */
/* .Instructor-PreviewContainer-Container {
    @apply flex w-full max-w-[600px]
             flex-col   justify-between     gap-4                 sm:flex-row 
             md:w-[45%]                     md:flex-col 
             md:gap-2                      lg:gap-3        2xl:flex-row    2xl:gap-5;
} */

/* .instructor-profile-image {
    background: #f0f0f7 0% 0% no-repeat padding-box;
    border: 3px dashed #c8c8c8;
    border-radius: 15px;
    opacity: 1;
    width: 165px;
    height: 164px;
} */

/* Add this CSS to your stylesheet */
.multiselect-container {
    border: none; /* Remove border from the container */
    background-color: white;
    border-radius: 10px;
}

/* Or, if needed, you can also target the dropdown options */
.multiselect-container .option {
    border: none; /* Remove border from the dropdown options */
    /* color: red; */
    /* background-color: #aeb0ff; */
}
.multiselect-container .option .onSelect {
    border: none; /* Remove border from the dropdown options */
    /* color: red; */
    background-color: #aeb0ff;
}

.multiselect-selected-option {
    background-color: red; /* Change this to the desired background color */
}

.searchBox {
    /* // To change search box element look */
    border: none;
    font-size: 20px;
    /* min-height: 10px; */
}
/* .inputField {
    To change input field position or margin
    margin: 5px;
} */
/* .chips {
    background: red;
} */
/* // To change css for option container  */
/* .optionContainer {
    background-color: red;
} */
/* // To change css for dropdown options */

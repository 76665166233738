header {
  @apply relative flex flex-col justify-between items-center z-30 border-[6px];
}
.HeaderDataContainer {
  @apply flex justify-between items-center fixed top-0 lg:bg-white  bg-[#321d6d]  w-full h-[80px];
}
.HeaderDataContainer_2{
  background: transparent linear-gradient(93deg, #AEB0FF 0%, #B05BC4 100%) 0% 0% no-repeat padding-box;
}
.DropDownBorder {
  border: none;
  border-bottom: 1px solid #f1f1f3;
}

.ArrowBorder {
  border-bottom: 10px solid red;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  background-color: #ffffff;
  text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.116);
}

.Shadow {
  filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.5));
}

.DropDown-Menu {
  opacity: 0;
  pointer-events: none;
  transform: translateY(-20px);
}
.DropDown.active > .DropDown-Menu {
  opacity: 1;
  transition: 150ms ease-in-out;
  transform: translateY(0px);
  pointer-events: auto;
}

.fontStyle {
  font-size: inherit;
  color: inherit;
  font-family: inherit;
  font-weight: inherit;
}
@media (max-width: 1024px) {
  .SideBarBG {
    background: linear-gradient(
      305.35deg,
      rgba(69, 16, 138, 0.95) 10.63%,
      rgba(69, 16, 138, 0.95) 12.39%,
      rgba(18, 5, 78, 0.95) 97.86%,
      rgba(16, 5, 77, 0.95) 97.86%
    );
  }
  .DropDown-Menu {
    transform: translateY(-20px);
  }
}

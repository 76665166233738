.VehicleBorder{
    border-bottom: 1px solid #AAAAAA;
}
.Shadowa{
    box-shadow: 3px 3px 6px #00000029;
}
.details {
  
        box-shadow: 3px 3px 6px #00000029;

}
.description {
    color: white !important;
}
.SideBarSvgSelected {
  background: transparent
    linear-gradient(90deg, rgba(255, 255, 255, 0.47) 0%, #80808000 100%) 0% 0%
    no-repeat padding-box;
  border-left: 8px solid #fff;
  color: white;
}
.SideBarSvgUnSelected {
  background: rgba(0, 0, 0, 0);
  border-left: 8px solid #a3a0fb00;
}

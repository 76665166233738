.Intsructor-InputContainer {
    @apply flex w-11/12 flex-col gap-2  rounded-md bg-white

    pt-7  pb-10   shadow-[4px_5px_6px_#00000029]  sm:gap-2  sm:rounded-lg
    md:gap-2  md:rounded-2xl    lg:gap-2       lg:rounded-3xl       xl:gap-[10px]       xl:rounded-[38px]       2xl:gap-2;
}

.Instructor-SubmitBtn {
    @apply cursor-pointer rounded-3xl border-none bg-[#4C34AD]  px-7 py-3 text-3xs text-white outline-none
    duration-[250ms] hover:bg-white 
    hover:text-[#A1A3EF]                    md:text-2xs     lg:text-xs      xl:text-base;
}

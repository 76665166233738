/* .StudentNumberCardBg {
    background: linear-gradient(-45deg, #9aff00 0%, #3dd7ff 100%);
}

.WeekCardBg {
    background: linear-gradient(137deg, #f9d324 0%, #ff564e 100%);
}
.TimeCardBg {
    background: linear-gradient(-44deg, #0fd850 0%, #e8ee48 100%);
}
.CompleteCourseCardBg {
    background: linear-gradient(180deg, #b224ef 0%, #7579ff 100%);
}

.TeacherCards {
    @apply flex w-1/4 max-w-[250px] cursor-pointer flex-col items-center gap-3 rounded-md px-5 pb-[50px] pt-[58px];
} */
.InstructorDashboardContainer {
    @apply relative w-full
       px-[2px] sm:pr-8 sm:pl-5 md:pr-10 lg:pr-8 xl:pr-10 
  
     2xl:pr-10 3xl:pr-20;
}
.bg-color {
    background: transparent
        linear-gradient(114deg, #ffffff 0%, #f6f6ff 14%, #d6d8ff 100%) 0% 0%
        no-repeat padding-box;
    opacity: 1;
}

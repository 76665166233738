/* .time-rang-cnt{
    border: 1px solid gray;
} */

/* Hide the clock icon in the time input */
/* .time-input::-webkit-inner-spin-button,
.time-input::-webkit-calendar-picker-indicator {
  display: none;  
} */

.time-input {
  -webkit-appearance: none;  /* Safari & Chrome */
  -moz-appearance: textfield;  /* Firefox */
  appearance: none;
  border: unset;
  box-shadow: unset;
}


/* .addCourseinstructorImg {
    width: 50px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
} */
.ProfileImageContainer {
  @apply relative flex h-12 w-12 items-center justify-center overflow-hidden rounded-full text-center 
  lg:h-14 lg:w-14;
}

.addCourse4Status {
  max-width: 180px;
  width: 100%;

  text-align: center;
  padding: 10px 0px;
  /* font-weight: bold; */
  border-radius: 7px;

  font-size: 15px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.relativeTableData{
  position: relative;
}

@media (max-width: 1300px) {
  .addCourse4Status {
    width: 120px;
    font-size: 13px;
  }
}

@media (max-width: 1120px) {
  .addCourse4Status {
    font-size: 12px;
  }
}

@media (max-width: 700px) {
  /* .addCourseinstructorImg {
        width: 30px;
    } */

  .addCourse4Status {
    width: 90px;
    height: 25px;
    padding-top: 5px;
    font-size: 10px;
  }
}

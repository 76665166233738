.CustomPhoneNumberInput {
    width: 100%;
    height: 49px; /* Default height for screens wider than 640px */
  }
  
  @media (max-width: 640px) {
    .CustomPhoneNumberInput {
      width:100%;
      height: 45px; /* Height for screens narrower than 640px */
    }
  }
  
.BG_Image {
  background-image: url("./Assets/Signup\ BG\ image.png");
  background-position: center;
}

@media (min-width: 768px) {
  .DropShadow {
    box-shadow: 3px 3px 6px #00000037;
  }
}

.LeftBorders {
  border-radius: 45px;
  border-color: red;
  border-width: 2px;
  border-style: dashed;
}
.LightBg {
  @apply fixed top-0 z-50 flex max-h-screen w-full overflow-scroll bg-[#00000037] md:justify-center;
}
.MainParent {
  @apply flex   h-fit w-full flex-col items-start justify-start
    min-h-screen md:min-h-[calc(100vh_-_112px)] md:w-[95%]
    md:mt-12 lg:mt-16 xl:mt-20 2xl:mt-24 3xl:mt-28 
  ;
}

.ImageContainer {
  @apply  hidden 
    min-h-[100px]         w-full
    overflow-hidden  md:block md:min-h-[144px] lg:min-h-[130px]
    xl:min-h-[320px];
}
.FormParent {
  @apply relative flex h-fit min-h-full w-full flex-col  justify-start md:h-auto md:min-h-[auto] lg:items-center lg:justify-between lg:bg-white;
}


.LicenseShadow {
    box-shadow: 15px 15px 10px rgba(0, 0, 0, 0.16);

}

.btnShadow {
    box-shadow: 0px 3px 6px rgba(0, 60, 255, 0.30);
}



.hover-on-child:hover #LicenseTypeInfo {

    width: 70%;
    gap: 24px;
}

.hover-on-child:hover #LicenseTypeImage {
    scale: .9;
}



@media (max-width:1024px) {
    .hover-on-child:hover #LicenseTypeInfo {
        width: 80%;
        gap: 16px;
    }
}

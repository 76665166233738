
@keyframes underline {
    from {
      width: 100%;
     
    }
    to {
      width:0;
      
    }
  }
  
  .success-message-underline {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    background-color: #03543f;
    border-radius: 2px;
    animation: underline 4s ease-out;
  }
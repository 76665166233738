.InstructorPageBtns {
    border: 1px solid #e3e3e3;
    @apply my-4 h-fit w-1/2 min-w-fit max-w-[390px] cursor-pointer bg-white py-4 text-center text-6xs text-[#174A84]  no-underline
    shadow-[0px_3px_6px_#00000029]       sm:w-2/5
    sm:whitespace-nowrap    sm:text-5xs     md:text-4xs     lg:text-3xs     xl:text-2xs;
}

.TeacherFilterFieldsBorder {
    border: none;
    transition: 0.1s;
    border-bottom: 1.5px solid rgba(27, 27, 27, 0.1);
}

.StaffNewFilterFieldsBorder {
    border: none;
    transition: 0.1s;
    border-bottom: 1.5px solid #ffffff;
}

.TeacherFilterFieldsBorder:focus {
    outline: none;
    border-bottom: 1.5px solid rgba(27, 27, 27, 0.767);
}

.instructorDiv {
    background: transparent
        linear-gradient(114deg, #ffffff 0%, #f6f6ff 14%, #d6d8ff 100%) 0% 0%
        no-repeat padding-box;
}

.addCourse4Table tbody tr td {
    /* padding-left: 45%; */
    margin-bottom: 12px;
}

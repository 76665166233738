.VehicleTbg {
    background-image: url("./Assets/VehicleTypeBG.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    /* background-size: cover; */
}


.hover-on-child:hover #VehicleTypeInfo {

    width: 70%;
    gap: 24px;
}

.hover-on-child:hover #VehicleTypeImage {
    scale: .9;
}

.NavigateButton_DropShadow {
    box-shadow: 0px 3px 6px #A1A3EF;
}

.VehicleType_DropShadow {
    box-shadow: 15px 15px 6px rgba(0, 0, 0, 0.16);
}

@media (max-width:1024px) {
    .hover-on-child:hover #VehicleTypeInfo {
        width: 80%;
        gap: 16px;
    }
}


.VehicleType {
    background-color: #A1A3EF;
    box-shadow: 0px 3px 6px #A1A3EF;
    transition: 0.4s;
}

.VehicleTypeRemove {
    background-color: #A1A3EF19;
    box-shadow: 0px 3px 6px #A1A3EF;
}
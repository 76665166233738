/* F0F0F7 */
.calenderClassTecherDash {
    width: 100%;
    max-width: 334px;
    background-color: #f0f0f7 !important;
    padding: 30px 24px;
    padding-top: 70px;
    border: none !important;
    border-radius: 24px;
    filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.16));
    margin-bottom: 1rem;
}

.react-calendar__tile--now {
    /* background: #cae7d8 !important; */
    /* color: #58ba6d !important; */
    background: none !important;
    color: none !important;

    font-weight: bold;
    border-radius: 50%;
}

.react-calendar__tile--now:hover {
    background-color: #58ba6d !important;
    color: #cae7d8 !important;
}

.react-calendar__tile--active {
    background: #ccdbf9 !important;
    color: #4b8ffe !important;
    border-radius: 50%;
    font-weight: bold;
}

.react-calendar__tile--active:hover {
    background: #4b8ffe !important;
    color: #ccdbf9 !important;
}

.calenderClassTecherDashDiv {
    max-width: 334px;
    height: 383px;
    border-radius: 24px;
    filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.16));
    background: #ffffff;
    /* padding: 22px 35px 42px 35px; */
}

.calenderClassTecherDashHeading {
    position: relative;
    top: 40px;
    font-size: 18px;
    z-index: 99;
    color: #4d4f5c;
    left: 30px;
}

.tile-Online {
    background: #7f82f9 !important;
    color: white !important;
    border-radius: 50%;
    font-weight: bold;
    width: 50%;
}

.tile-Driving {
    background: #a1a3ef !important;
    color: white !important;
    border-radius: 50%;
    font-weight: bold;
}

.tile-InClass {
    background: #5456c7 !important;
    color: white !important;
    border-radius: 50%;
    font-weight: bold;
}

.InstructorPageBtns {
    /* border: 1px solid #E3E3E3; */
    @apply  py-4 no-underline text-[#174A84] my-4 shadow-[0px_3px_6px_#00000029] max-w-[390px] text-center cursor-pointer h-fit sm:whitespace-nowrap  min-w-fit
    w-1/2       sm:w-2/5
    text-6xs    sm:text-5xs     md:text-4xs     lg:text-3xs     xl:text-2xs
}

.InstructorAddMemberPageBtns {
    /* border: 1px solid #E3E3E3; */
    @apply  py-4 px-[50px] no-underline text-[#174A84] my-4 shadow-[0px_3px_6px_#00000029] max-w-[390px] text-center cursor-pointer h-fit sm:whitespace-nowrap  min-w-fit
    w-1/2       sm:w-2/5
    text-6xs    sm:text-5xs     md:text-4xs     lg:text-3xs     xl:text-2xs
}

.TeacherFilterFieldsBorder {
    border: none;
    transition: .1s;
    border-bottom: 1.5px solid rgba(27, 27, 27, 0.1);
    
}

.TeacherFilterFieldsBorder:focus {
    outline: none;
    border-bottom: 1.5px solid rgba(27, 27, 27, 0.767);
}

.instructorDiv {
    background: transparent linear-gradient(114deg, #FFFFFF 0%, #F6F6FF 14%, #D6D8FF 100%) 0% 0% no-repeat padding-box;
    position: relative;

}

.relativeTableData {
    position: relative;
    overflow: visible; /* Ensure dropdown isn't clipped */
}

.absoluteTableData {
    position: absolute;
    z-index: 99999;
}

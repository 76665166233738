@tailwind base;
@tailwind components;
@tailwind utilities;



body{
  min-width: 300px;
  max-width: 2100px;
  margin: 0 auto;
}
.HeaderDataContainer{
  max-width: 2100px;
}
html,
body,
#root {
  height: 100%;
}
@media (max-width: 768px) {
  body {
    background-color: #4e00eb16;
  }
}
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: var(--Regularfont);
}

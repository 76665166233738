.MyCoursesTopBG {
    background-image: linear-gradient(70deg, #170654, #440F88);
}

.ButtonBorder {
    border-bottom: 8px solid #A1A3EF;
}



@media (max-width: 768px) {
    .MyCoursesTopBG {
        background-image: linear-gradient(165deg, #291C5F, #5722A4, #410F86);
    }
}
.HeadingBorder {
    border: none;
    border-bottom: 2px solid white;
}

.StatsBG {
    background-image: url('./Components/CategoryTwo/Assets/Class\ G2\ BG.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;


}

.borderStyle {
    border-bottom: 10px solid #acadf143;
}

.ClassG2BG {
    background-image: url('./Components/CategoryTwo/Assets/Class\ G2\ BG.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-top: 15px solid #acadf143;
    border-bottom: 10px solid #acadf143;
}

@media (max-width:639px) {
    .ClassGLicensingMobileGradient {
        background-image: linear-gradient(165deg, #291C5F, #5722A4, #410F86);
    }

    .ClassG2BG {
        background-image: url('../LandingPage/PopularCourses/Assets/Shape.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border: none;
    }
}

.G1Licensing,
.G2Licensing {
    background-color: #A1A3EF;
    box-shadow: 0px 3px 6px #A1A3EF;
}

.G1LicensingRemove,
.G2LicensingRemove {
    background-color: #A1A3EF19;
    box-shadow: 0px 3px 6px #A1A3EF;
}


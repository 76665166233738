.rating-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
  }
  
  .on .star {
    @apply text-violet-100
  }
  
  .off .star {
    color: gray;
  }
  
  .star {
    font-size: 2rem;
    padding: 0.1rem;
  }
  
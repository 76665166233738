/* .addCourseinstructorImg {
    width: 50px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
} */
.ProfileImageContainer {
    @apply relative flex h-8 w-8 items-center justify-center overflow-hidden rounded-full text-center 
    lg:h-14 lg:w-14;
}

.addCourse4Status {
    max-width: 200px;
    width: 100%;

    text-align: center;
    padding: 10px 5px;
    font-weight: bold;
    border-radius: 5px;

    font-size: 15px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}
.bg-color {
    background: transparent
        linear-gradient(114deg, #ffffff 0%, #f6f6ff 14%, #d6d8ff 100%) 0% 0%
        no-repeat padding-box;
    opacity: 1;
}
@media (max-width: 1300px) {
    .addCourse4Status {
        width: 120px;
        font-size: 13px;
    }
}

@media (max-width: 1120px) {
    .addCourse4Status {
        font-size: 12px;
    }
}

@media (max-width: 700px) {
    /* .addCourseinstructorImg {
          width: 30px;
      } */

    .addCourse4Status {
        width: 80px;
        height: 25px;
        padding-top: 5px;
        font-size: 9px;
    }
}

.LeftCardsContainer {
  @apply mt-4 flex  h-fit
    w-full                                                          
    flex-col                items-center gap-2
                              
    md:flex-row;
}

.LeftCardsContainer > div {
  @apply flex w-full flex-col flex-wrap
    items-center                                                       
    justify-center         self-stretch xs:flex-row
    sm:items-start                  md:justify-start 
    xl:flex-nowrap max-w-[100%] xl:overflow-x-scroll overflow-y-hidden;
}

.background-image-1 {
  background-image: url("../Assets/student-3.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.background-image-2 {
  background-image: url("../Assets/vehicle-3.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.background-image-3 {
  background-image: url("../Assets/instructor-3.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.background-image-4 {
  background-image: url("../Assets/weekly-schedule.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.background-image-5 {
  background-image: url("../Assets/lessons.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.StaffCards {
  @apply flex h-[326px] xl:h-[248px] min-w-[230px] max-w-[250px] xl:min-w-[170px]  xl:max-w-[170px] cursor-pointer flex-col  items-center gap-1  self-auto rounded-md px-5  pb-[50px] pt-[58px] xs:max-w-[230px]
       xs:self-stretch                 
    sm:max-w-[250px]       md:w-[230px];
}

/* .StaffCards {
  @apply flex h-[248px] xl:min-w-[170px]  xl:max-w-[170px] cursor-pointer flex-col  items-center gap-1  self-auto rounded-md px-5  pb-[50px] pt-[58px] xs:max-w-[170px]
       xs:self-stretch                 
    sm:max-w-[170px]       md:w-[170px];
} */

.ClickNoCard {
  background: linear-gradient(137deg, #f9d324 0%, #ff564e 100%);
}

/* .CarCard {
  background: linear-gradient(137deg, #e8ee48 0%, #0fd850 100%);
} */

/* .InstructorCard {
  background: linear-gradient(180deg, #b224ef 0%, #7579ff 100%);
} */

.overlay-1 {
  background-color: #000000;
  width: 90%;
  opacity: 0.3;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}
.overlay-2 {
  background-color: #000000;
  width: 90%;
  opacity: 0.3;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}

.hoverPopup {
    -webkit-box-shadow: 0px 1px 9px -2px #787878;
    -moz-box-shadow:0px 1px 9px -2px #787878;
    box-shadow: 0px 1px 9px -2px #787878;
}


.shadow {
    -webkit-box-shadow: 0px 1px 9px -2px #787878;
    -moz-box-shadow:0px 1px 9px -2px #787878;
    box-shadow: 0px 1px 9px -2px #787878;
}
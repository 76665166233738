.descriptionIconTxt div {
  background-color: #a1a3ef;
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.descriptionIconTxt div img {
  width: 40px;
  padding-top: 8px;
}
.descriptionIconTxt h6 {
  font-size: 19px;
  color: #a1a3ef;
}
.addCourse2Main {
  width: 90%;
  margin: 0 auto;
}
.col5Descriptions {
  margin-top: 60px;
}
.col5Descriptions .col-5 h6 {
  font-size: 19px;
  font-weight: normal;
  margin-bottom: 12px;
  line-height: 19px;
}
.col5Descriptions .col-5 p {
  font-size: 15px;
  color: rgb(138, 138, 138);
  line-height: 16px;
}
.addCourse2Texterea1 {
  height: 100px;
  width: 85%;
  box-shadow: 5px 5px 5px -2px rgb(165, 165, 165);
  border: 1px solid rgb(241, 241, 241);
  border-radius: 5px;
  outline: none;
  padding: 20px;
}
.addCourse2Texterea1WordsCount {
  font-size: 12px;
  width: 85%;
  text-align: right;
  color: rgb(119, 119, 119);
}
.addCourse2Texterea2 {
  height: 140px;
  padding: 10px;
}
.addCourse2Texterea3 {
  height: 60px;
}
.addCourse2AddFAQBtn {
  padding: 5px 10px;
  background-color: #a1a3ef;
  border: none;
  border-radius: 7px;
  position: relative;
  top: 10px;
  color: white;
  font-size: 16px;
}
@media (max-width: 700px) {
  .descriptionIconTxt div {
    width: 40px;
    height: 40px;
  }
  .descriptionIconTxt div img {
    width: 15px;
  }
  .descriptionIconTxt h6 {
    font-size: 12px;
  }
  .col5Descriptions .col-5 h6 {
    font-size: 11px;
    margin-bottom: 2px;
  }
  .col5Descriptions .col-5 p {
    font-size: 9px;
    line-height: 12px;
  }
  .addCourse2Texterea1 {
    height: 60px;
    font-size: 10px;
    padding: 5px;
  }
  .addCourse2Texterea1WordsCount {
    font-size: 9px;
  }
  .addCourse2Texterea2 {
    height: 100px;
  }
  .addCourse2AddFAQBtn {
    font-size: 9px;
  }
  .addCourse2Main {
    width: 100%;
  }
}

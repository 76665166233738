
.Radio-Btn .CheckMark {
    width: 2px;
    height: 2px;
    background-color: #222;
    display: none;
}

.Radio-Btn input {
    display: none;
}

.Radio-Btn input:checked+.CheckMark {
    display: inline-block;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    border-radius: 20px;
    background-color: #A1A3EF;
}

/* Radio Btn Style End */


.FreeTimeShadow {
    box-shadow: 0px 3px 6px #00000016;
}

/* TimeSlots.css */
/* Style for the overall TimePicker container */
.custom-time-picker {
    width: 350px; /* Adjust the overall container width if needed */
    font-size: 16px;
    text-align: center;
}

/* Style for the input field within the TimePicker */
.custom-time-picker input {
    width: 100%;
    padding: 5px;
    font-size: 16px;
    text-align: center;
}

/* Style for the time picker dropdown (hour and minute) */
/* Target the hour input specifically */
.react-time-picker__inputGroup__hour {
    width: 80px !important; /* Increase the width and use !important to override inline styles */
    padding: 5px;
    font-size: 16px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Target the minute input specifically */
.react-time-picker__inputGroup__minute {
    width: 80px !important; /* Similarly for minutes */
    padding: 5px;
    font-size: 16px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Optionally target all inputs in the input group */
.react-time-picker__inputGroup__input {
    width: 80px !important; /* General rule for input fields in the TimePicker */
    padding: 5px;
    font-size: 16px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Optional: Adjusting wrapper to remove borders or modify styles */
.react-time-picker__wrapper {
    border-radius: 5px;
}

/* Optional: Adjust the width of the time picker clock icon, if needed */
.react-time-picker__clock {
    display: none; /* Hide the clock icon if it's not needed */
}





@media (min-width: 0px) {
    .Radio-Btn {

        width: 20px;
        height: 20px;
    }
}

@media (min-width: 640px) {
    .Radio-Btn {

        width: 22px;
        height: 22px;
    }
}

@media (min-width: 768px) {
    .Radio-Btn {

        width: 24px;
        height: 24px;
    }
}

@media (min-width: 1024px) {
    .Radio-Btn {

        width: 26px;
        height: 26px;
    }
}

@media (min-width: 1280px) {
    .Radio-Btn {

        width: 28px;
        height: 28px;
    }
}

@media (min-width: 1536px) {
    .Radio-Btn {

        width: 30px;
        height: 30px;
    }
}

.Radio-Btn {

    width: 30px;
    height: 30px;
    border: 3px solid #A1A3EF;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

#TimeSlotScrollbar::-webkit-scrollbar {
    cursor: pointer;
    height: 10px;
    width: 11px;
}

#TimeSlotScrollbar::-webkit-scrollbar-thumb {
    cursor: pointer;
    background-color: #A1A3EF;
    border-radius: 10px;
}

#TimeSlotScrollbar::-webkit-scrollbar-track {

    cursor: pointer;
    background-color: #ef528c00;
}

.orlines::after {
    content: "";
  display: block;
  border-top: 3px solid #625ba8;
  width: 85px;
  height: 4px;
  position: relative;
    top: -12px;
    left: -67px;
}
.orlines::before {
    content: "";
  display: block;
  border-top: 3px solid #625ba8;
  width: 85px;
  height: 4px;
  top: 16px;
    left: 65px;
    position: relative;
}
.orlines {
    margin:5px 0px;
}
.recaptcha-container {

  width:300px ; 
  overflow: hidden;
}
.rec {
  width: fit-content;
  overflow-x: hidden;
}

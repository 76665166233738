.SchoolBackGroundImage {
    background-image: url('./CourseIntro/Assets/Course\ Image.png');
    background-size: cover;
    border: 1px solid black;
    background-repeat: no-repeat;
}



.NavigateButtonBorder {
    border: none;
    border-bottom: 8px solid #A1A3EF;
}

.CourseInstructorsBG {
    background-image: url('./CourseInstructors/Assets/Course\ Teachers\ BG.png');
    background-repeat: no-repeat;
    background-size: cover;

}

.RevewTileShadow {
    box-shadow: 15px 15px 50px #65656540;
}

@media (max-width:639px) {

    .MobileGradient {
        background-image: linear-gradient(165deg, #291C5F, #5722A4, #410F86);
    }



    .NavigateButtonBorder {
        border-bottom: 3px solid #A1A3EF;
    }

}
.InsDashboardContainer {
  @apply relative w-full
    sm:pr-8 md:pr-10 lg:pr-8 xl:pr-10 3xl:pr-20

   px-[4px] sm:pl-5

}
.CoursesGraphContainer{
    @apply flex gap-5 my-[34px] w-full justify-between
            flex-col                                    1xl:flex-row
}
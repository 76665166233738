.RightSideAndSideBar {
    @apply absolute -left-12  top-0  mx-12
    mr-3    flex           h-[95vh]     min-h-fit    w-full
              flex-col         items-start    justify-start
    gap-[18px] lg:gap-[24px]
                       rounded-b-[60px]            p-10  md:gap-[22px] lg:static  
    lg:h-full            
    lg:flex-row            
    lg:items-center       lg:justify-end
    lg:gap-5   
    lg:rounded-none         lg:p-0;
}

/* .RightSideAndSideBar {
    @apply absolute top-0  -left-12 mx-12 flex
  h-[80vh]               min-h-fit     w-full    flex-col
  flex-wrap               items-start    justify-start    gap-7
  rounded-b-[60px]                p-10
  lg:static            lg:mx-5 
  lg:h-full            lg:w-auto  
  lg:flex-row       lg:items-center
  lg:justify-end    lg:gap-7 
  lg:rounded-none         lg:p-0 
  xl:mx-10            xl:gap-8 
  2xl:mx-16              2xl:gap-11;
} */
.HeaderRightSide-LinkStyle {
    @apply cursor-pointer border-none bg-[#ffffff00] text-[18px] lg:text-2xs text-white no-underline hover:text-[#A1A3EF] md:text-xs  lg:text-4xs  lg:text-black xl:text-2xs 2xl:text-[21px];
}

.CrossButton {
    @apply absolute right-2 top-2 flex cursor-pointer items-center justify-center rounded-xl border-none bg-[#A1A3EF] p-3 lg:hidden;
}

*{
    scroll-behavior:smooth
}

.EnrollmentBG {
    background-image: url('./Assets/Enrollmentbg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.EnrollmentStepsShadow {
    box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.16);
}



/* In UserInfo-> ContactAndOtherInfo -> DatePortion */

.tooltip-text {
    visibility: hidden;
    width: 120px; /* Adjust width as needed */
    background-color: #4C34AD; /* Background color of the tooltip */
    color: #fff; /* Text color */
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: relative;
    z-index: 1;
    bottom: 125%; /* Positioning above the icon */
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .icon-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
    z-index: 111111111111;
  }
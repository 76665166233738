.BG_IMAGEE {
    background: transparent url('../Assets/back1.png') 0% 0% no-repeat padding-box;
    backdrop-filter: blur(16px);
-webkit-backdrop-filter: blur(16px);
background-size: cover;




}

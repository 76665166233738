.InstructorCardInfoContainer{
    border-top: 1px solid #EBEBEB;
    border-bottom: 1px solid #EBEBEB;
    @apply flex flex-col py-1 px-4 gap-2 w-full mt-1 mb-3 min-h-[96px] text-[12px]
}

.active {
    text-decoration: underline;
    text-decoration-thickness: 4px;
    text-underline-offset: 9px;
    text-decoration-color: #a1a3ef;
}
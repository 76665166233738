.addCourse4Table {
    /* background-color: red !important; */
    max-width: 100% !important;
    border-spacing: 1;
    border-collapse: collapse;
    background: white;
    border-radius: 6px;
    overflow: hidden;
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    position: relative;


}

.addCourse4Table * {
    position: relative;
}
.instructorDiv {
    background: transparent
        linear-gradient(114deg, #ffffff 0%, #f6f6ff 14%, #d6d8ff 100%) 0% 0%
        no-repeat padding-box;
}

.addCourse4Table td,
.addCourse4Table th {
    padding-left: 10px;
    color: #083e7c;
    font-size: 15px;
}

.addCourse4Table thead tr th {
    font-weight: normal;
    /* text-align: start; */
}

.addCourse4Table thead tr {
    height: 60px;
    /* background: #ffed86; */
    border-bottom: 2px solid #d3e4ff;
    border-top: 2px solid #d3e4ff;
}

.addCourse4Table tbody tr {
    height: 80px;
    border-bottom: 2px solid #d3e4ff;
    /* padding: 20px 0px; */
}

.addCourse4Table tbody tr:first-child {
    border-top: 2px solid #000;
}
.mainContainer1White {
    /* background-color: white; */
    padding: 20px;
    border-radius: 30px;
    margin-top: 40px;
    /* box-shadow: 30px 30px 40px -40px rgb(117, 114, 114); */
}

 @media screen and (max-width: 35.5em) {
    .addCourse4Table {
        display: block;
    }

    .addCourse4Table > *,
    .addCourse4Table tr,
    .addCourse4Table td,
    .addCourse4Table th {
        display: block;
    }

    .addCourse4Table thead {
        display: none;
    }

    .addCourse4Table tbody tr {
        height: auto;
        padding: 8px 0;
    }

    .addCourse4Table tbody tr td {
        padding-left: 45%;
        margin-bottom: 12px;
    }

    .addCourse4Table tbody tr td:last-child {
        margin-bottom: 0;
    }

    .addCourse4Table tbody tr td:before {
        position: absolute;
        font-weight: 700;
        width: 40%;
        left: 10px;
        top: 0;
    }

    .addCourse4Table tbody tr td:nth-child(1):before {
        content: "ID";
    }


    .addCourse4Table tbody tr td:nth-child(2):before {
        content: "";
    }

    .addCourse4Table tbody tr td:nth-child(3):before {
        content: "Name";
    }

    .addCourse4Table tbody tr td:nth-child(4):before {
        content: "Email";
    }

    .addCourse4Table tbody tr td:nth-child(5):before {
        content: "Status";
    }

  

    .addCourse4Table tbody tr td:nth-child(7):before {
        content: "Clients";
    }

    .addCourse4Table tbody tr td:nth-child(8):before {
        content: "Total Classes";
    }

    .addCourse4Table tbody tr td:nth-child(9):before {
        content: "";
    }
    .addCourse4Table .td {
        text-align: center;
    }

} 


/* Instructor Selection table  */
@media screen and (max-width: 35.5em) {
    .instructor_selection {
        display: block;
    }

    .instructor_selection > *,
    .instructor_selection tr,
    .instructor_selection td,
    .instructor_selection th {
        display: block;
    }

    .instructor_selection thead {
        display: none;
    }

    .instructor_selection tbody tr {
        height: auto;
        padding: 8px 0;
    }

    .instructor_selection tbody tr td {
        padding-left: 45%;
        margin-bottom: 12px;
    }

    .instructor_selection tbody tr td:last-child {
        margin-bottom: 0;
    }

    .instructor_selection tbody tr td:before {
        position: absolute;
        font-weight: 700;
        width: 40%;
        left: 10px;
        top: 0;
    }
    
    
    .instructor_selection tbody tr td:nth-child(1):before {
        content: "";
    }
    .instructor_selection tbody tr td:nth-child(2):before {
        content: "ID";
    }


    .instructor_selection tbody tr td:nth-child(3):before {
        content: "Name";
    }

    .instructor_selection tbody tr td:nth-child(4):before {
        content: "Email";
    }

    .instructor_selection tbody tr td:nth-child(5):before {
        content: "Status";
    }

    /* .instructor_selection tbody tr td:nth-child(6):before {
        content: "Joining Date";
    } */

    .instructor_selection tbody tr td:nth-child(6):before {
        content: "Clients";
    }

    .instructor_selection tbody tr td:nth-child(7):before {
        content: "Total Classes";
    }
    .instructor_selection tbody tr td:nth-child(8):before {
        content: "Actions";
        
    }

    /* .instructor_selection tbody tr td:nth-child(9):before {
        content: "Actions";
    } */
    /* .instructor_selection .td {
        text-align: center;
    } */

}

/* instructor_students_table  */
 @media screen and (max-width: 35.5em) {
    .instructor_students_table {
        display: block;
    }

    .instructor_students_table > *,
    .instructor_students_table tr,
    .instructor_students_table td,
    .instructor_students_table th {
        display: block;
    }

    .instructor_students_table thead {
        display: none;
    }

    .instructor_students_table tbody tr {
        height: auto;
        padding: 8px 0;
    }

    .instructor_students_table tbody tr td {
        padding-left: 45%;
        margin-bottom: 12px;
    }

    .instructor_students_table tbody tr td:last-child {
        margin-bottom: 0;
    }

    .instructor_students_table tbody tr td:before {
        position: absolute;
        font-weight: 700;
        width: 40%;
        left: 10px;
        top: 0;
    }
    
    
    .instructor_students_table tbody tr td:nth-child(1):before {
        content: "";
    }
    .instructor_students_table tbody tr td:nth-child(2):before {
        content: "ID";
    }


    .instructor_students_table tbody tr td:nth-child(3):before {
        content: "Name";
    }

    .instructor_students_table tbody tr td:nth-child(4):before {
        content: "Email";
    }

    .instructor_students_table tbody tr td:nth-child(5):before {
        content: "Status";
    }

    .instructor_students_table tbody tr td:nth-child(6):before {
        content: "Joining";
    }

    .instructor_students_table tbody tr td:nth-child(7):before {
        content: "Total Classes";
    }
    .instructor_students_table tbody tr td:nth-child(8):before {
        content: "Next Class";
        
    }

} 


/* for Instructor mobile Screen instructor_selection*/
@media screen and (max-width: 35.5em) {
    .Instructor_table {
        display: block;
    }

    .Instructor_table > *,
    .Instructor_table tr,
    .Instructor_table td,
    .Instructor_table th {
        display: block;
    }

    .Instructor_table thead {
        display: none;
    }

    .Instructor_table tbody tr {
        height: auto;
        padding: 8px 0;
    }

    .Instructor_table tbody tr td {
        padding-left: 45%;
        margin-bottom: 12px;
    }

    .Instructor_table tbody tr td:last-child {
        margin-bottom: 0;
    }

    .Instructor_table tbody tr td:before {
        position: absolute;
        font-weight: 700;
        width: 40%;
        left: 10px;
        top: 0;
    }
    
    
    .Instructor_table tbody tr td:nth-child(1):before {
        content: "";
    }
    .Instructor_table tbody tr td:nth-child(2):before {
        content: "ID";
    }


    .Instructor_table tbody tr td:nth-child(3):before {
        content: "Name";
    }

    .Instructor_table tbody tr td:nth-child(4):before {
        content: "Email";
    }

    .Instructor_table tbody tr td:nth-child(5):before {
        content: "Status";
    }

    /* .Instructor_table tbody tr td:nth-child(6):before {
        content: "Joining Date";
    } */

    .Instructor_table tbody tr td:nth-child(6):before {
        content: "Clients";
    }

    .Instructor_table tbody tr td:nth-child(7):before {
        content: "Actions";
        padding-top: 20px;
       
    }
    /* .Instructor_table tbody tr td:nth-child(7):before {
        content: "Total Classes";
    } */

    .Instructor_table tbody tr td:nth-child(8):before {
        content: "";
    }
    /* .Instructor_table .td {
        text-align: center;
    } */

}


/* for Staff mobile Screen   */
@media screen and (max-width: 35.5em) {
    .staff_table {
        display: block;
    }

    .staff_table > *,
    .staff_table tr,
    .staff_table td,
    .staff_table th {
        display: block;
    }

    .staff_table thead {
        display: none;
    }

    .staff_table tbody tr {
        height: auto;
        padding: 8px 0;
    }

    .staff_table tbody tr td {
        padding-left: 45%;
        margin-bottom: 12px;
    }

    .staff_table tbody tr td:last-child {
        margin-bottom: 0;
    }

    .staff_table tbody tr td:before {
        position: absolute;
        font-weight: 700;
        width: 40%;
        left: 10px;
        top: 0;
    }
    
    
    .staff_table tbody tr td:nth-child(1):before {
        content: "";
    }
    .staff_table tbody tr td:nth-child(2):before {
        content: "ID";
    }


    .staff_table tbody tr td:nth-child(3):before {
        content: "Name";
    }

    .staff_table tbody tr td:nth-child(4):before {
        content: "Email";
    }

    .staff_table tbody tr td:nth-child(5):before {
        content: "Status";
    }

    /* .staff_table tbody tr td:nth-child(6):before {
        content: "Joining Date";
    } */

    .staff_table tbody tr td:nth-child(6):before {
        content: "Role";
    }

    .staff_table tbody tr td:nth-child(7):before {
        content: "Phone";
        
    }
    /* .staff_table tbody tr td:nth-child(7):before {
        content: "Total Classes";
    } */

    .staff_table tbody tr td:nth-child(8):before {
        content: "Actions";
        padding-top: 20px;
    }
    /* .staff_table .td {
        text-align: center;
    } */

}


/* for Students mobile Screen   */
@media screen and (max-width: 35.5em) {
    .student_table {
        display: block;
    }

    .student_table > *,
    .student_table tr,
    .student_table td,
    .student_table th {
        display: block;
    }

    .student_table thead {
        display: none;
    }

    .student_table tbody tr {
        height: auto;
        padding: 8px 0;
    }

    .student_table tbody tr td {
        padding-left: 45%;
        margin-bottom: 12px;
    }

    .student_table tbody tr td:last-child {
        margin-bottom: 0;
    }

    .student_table tbody tr td:before {
        position: absolute;
        font-weight: 700;
        width: 40%;
        left: 10px;
        top: 0;
    }
    
    
    .student_table tbody tr td:nth-child(1):before {
        content: "";
    }
    .student_table tbody tr td:nth-child(2):before {
        content: "ID";
    }


    .student_table tbody tr td:nth-child(3):before {
        content: "Name";
    }

    .student_table tbody tr td:nth-child(4):before {
        content: "Email";
    }

    .student_table tbody tr td:nth-child(5):before {
        content: "Status";
    }

    /* .student_table tbody tr td:nth-child(6):before {
        content: "Joining Date";
    } */

    .student_table tbody tr td:nth-child(6):before {
        content: "Phone";
    }

    .student_table tbody tr td:nth-child(7):before {
        content: "Actions";
        /* padding-top: 20px; */
    }
    .student_table tbody tr td:nth-child(8):before {
        content: "";
     
    }

}



.addCourse4Heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
}

.addCourse4Heading h4 {
    font-size: 18px;
    letter-spacing: 1px;
}

.FilterInstructorDiv {
    -webkit-box-shadow: 0 0 10px rgb(206, 206, 206);
    box-shadow: 0 0 8px 2px rgb(207, 207, 207);
    width: 280px;
    height: 50px;
    text-align: center;
}

.FilterInstructorDiv p {
    padding-top: 12px;
    font-size: 16px;
}

.FilterInstructorDiv img {
    width: 10px;
    float: right;
    margin-right: 20px;
    margin-top: 5px;
}

.addCourse4CheckBox {
    width: 30px;
    height: 30px;
    outline: 3px solid #c7bfff;
    /* background-color: transparent; */
    /* border: none; */
    border-radius: 3px;
    cursor: pointer;
}
.addCourse4CheckBox:checked {
    background-color: #c7bfff;
}

.addCoursePage5Container {
    width: 100%;
    height: 100vh;
    min-width: 100vh;
    /* background-color: red; */
    background-color: rgba(214, 214, 214, 0.45);
    position: fixed;
    top: 0px;
    left: 0px;
}

.addCourseFivePopup {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 80%);
    width: 350px;
    background-color: white;
    text-align: center;
    border-radius: 20px;
}

.addCourseFivePopup > .addCourseFivePopupCross img {
    float: right;
    margin: 7px;
    width: 20px;
}

.addCourseFivePopup h6 {
    padding-top: 15px;
    font-size: 15px;
    margin-left: 20px;
}

.addCourseFivePopup p {
    padding: 0px 30px;
    margin-top: 10px;
    font-size: 15px;
}

.addCourseFivePopup > .addCourseFivePopupVehicle img {
    width: 150px;
}

.addCourseFivePopup button {
    background-color: #a1a3ef;
    border-radius: 12px;
    border: none;
    font-size: 13px;
    padding: 5px 30px;
    color: white;
    margin-bottom: 17px;
}

@media (max-width: 1300px) {
    .addCourse4Table td,
    .addCourse4Table th {
        padding-left: 10px;
        font-size: 16px;
    }
}

@media (max-width: 1120px) {
    .addCourse4Table td,
    .addCourse4Table th {
        padding-left: 10px;
        font-size: 13px;
    }
}

@media (max-width: 1000px) {
    .addCoursePage5Container {
        height: 1100px !important;
    }
}

@media (max-width: 700px) {
    .addCourse4Table td,
    .addCourse4Table th {
        padding-left: 10px;
        font-size: 14px;
    }

    tbody tr td {
        white-space: nowrap !important;
    }

    .addCourse4Heading h4 {
        font-size: 12px;
    }

    .FilterInstructorDiv {
        width: 150px;
        height: 30px;
    }

    .FilterInstructorDiv p {
        padding-top: 6px;
        font-size: 11px;
    }

    .FilterInstructorDiv img {
        width: 4px;
        margin-right: 20px;
        margin-top: 6px;
    }
}

@media (max-width: 568px) {
    .addCoursePage5Container {
        height: 2400px !important;
    }
}

@media (max-width: 568px) {
    .addCourseFivePopup {
        width: 270px;
        left: 45%;
    }

    .addCoursePage5Container {
        width: 92%;
    }

    .addCourseFivePopup h6 {
        font-size: 15px;
    }

    .addCourseFivePopup p {
        font-size: 14px;
    }

    .addCourseFivePopup > .addCourseFivePopupVehicle img {
        width: 120px;
    }
}

@media (max-width: 400px) {
    .addCourse4Table tbody tr td {
        padding-left: 45%;
    }

    .FilterInstructorDiv {
        width: 100px;
        height: 25px;
    }

    .FilterInstructorDiv p {
        padding-top: 6px;
        font-size: 8px;
    }

    /* .FilterInstructorDiv img {
        width: 4px;
        margin-right: 10px;
        margin-top: 5px;
    } */
}

.select-box {
    flex-direction: column;
    max-width: 450px;
    max-height: 50px;
    position: relative;
}

.selected-value-container {
    cursor: pointer;
    width: 100%;
    /* padding: 10px 30px; */

    position: relative;
    @apply py-[10px];
}

.dropdown-option {
    padding: 8px 16px;
    cursor: pointer;
}

.dropdown-option:hover {
    background-color: #f0f0f0;
}

.dropdown-option.selected {
    background-color: #e0e0e0;
}

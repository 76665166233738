.addCourseFirstPageCont {
  width: 100%;
}
.addCourseFirstPage {
  width: 105%;
  background-color: transparent;
  box-shadow: none;
  /* background-color: rebeccapurple; */
}
.addCourseFIrstVehicalBox {
  box-shadow: 15px 15px 10px -10px rgb(197, 197, 197);
  border-radius: 12px;
  border: 1px solid rgb(224, 224, 224) !important;
}
.filterAddCOurse1 {
  /* background-color: red; */
  width: 90%;
  float: right;
  height: auto;
  margin-left: -30px;
  margin-top: 27px;
  box-shadow: 15px 15px 10px -10px rgb(197, 197, 197);
  border: 1px solid rgb(224, 224, 224) !important;
  padding: 25px;
  padding-left: 32px;
  padding-top: 32px;
}
.filterAddCOurse1 h5 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 52px;
}
.filterVehicleDiv {
  display: flex;
  justify-content: space-between;
  /* margin-top: 17px; */
  border-bottom: 2px solid rgb(233, 233, 233);
  padding: 10px 0px;
}
.filterVehicleDiv img {
  height: 7px;
}
.addCOurseFilterVehicleCaption {
  margin-top: 45px;
}
.filterButtonDiv {
  background-color: #a1a3ef;
  height: 48.8px;
  border-radius: 6px;
  filter: drop-shadow(0px 15px 15px rgba(67, 66, 93, 0.1));
  /* width: 273.9px; */
  width: 83%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 17px 10px;
  margin-top: 75px;
  margin-bottom: 17px;
}
.filterButtonDiv img {
  width: 15.4px;
  height: 15.4px;
}

.filterButtonDiv p {
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  font-style: normal;
  text-align: center;
  color: #ffffff;
}
.vehicleInventoryImgTopRate {
  position: absolute;
  right: 20px;
  margin-top: 20px;
}
.vehicleInventoryImgTopRate div {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #a1a3ef;
  text-align: center;
  padding-top: 5px;
  color: white;
  font-size: 14px;
}
.vehicleInventoryPictureWaterPrints {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  width: 80%;
  margin-top: -40px;
  display: flex;
  justify-content: space-between;
}
.waterPrintsGreen {
  width: 56px;
  height: 30.2px;
  border-radius: 5px;
  background: rgba(55, 182, 171, 0.8);
  font-size: 13px;
  text-align: center;
  padding-top: 5px;
  color: white;
}

.noteFromAdminFilter {
  height: 50px;
  padding: 15px 20px 10px 30px;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1200px) {
  .vehicleInventoryInventory {
    -ms-flex: 0 0 67%;
    flex: 0 0 67%;
    max-width: 67%;
  }
  .vehicleInventorySearch {
    -ms-flex: 0 0 33;
    flex: 0 0 33%;
    max-width: 33%;
  }
}
@media (max-width: 1000px) {
  .vehicleInventoryInventory
    > .vehicleInventrory
    > .addCourse3Main
    > .row
    > .col-3 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .vehicleInventorySearch {
    margin-left: -30px;
  }
}
@media (max-width: 780px) {
  .vehicleInventoryInventory
    > .vehicleInventrory
    > .addCourse3Main
    > .row
    > .col-3 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .vehicleInventoryInventory {
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
  }
  .vehicleInventorySearch {
    -ms-flex: 0 0 45%;
    flex: 0 0 45%;
    max-width: 45%;
    margin-left: -50px;
  }
  .filterButtonDiv {
    width: 100%;
  }
  .filterButtonDiv img {
    width: 13.4px;
    height: 13.4px;
  }
  .filterButtonDiv p {
    font-size: 14px;
    white-space: nowrap;
  }
}
.filterForMobileVehicleInventory {
  display: none;
}
@media (max-width: 500px) {
  .filterForMobileVehicleInventory {
    display: initial;
  }
  .vehicleInventoryInventory {
    -ms-flex: 0 0 90%;
    flex: 0 0 90%;
    max-width: 90%;
  }
  .vehicleInventorySearch {
    -ms-flex: 0 0 10%;
    flex: 0 0 10%;
    max-width: 10%;
    margin-left: -1px;
  }
  .filterAddCOurse1 {
    display: none;
  }
  .filterButtonDiv img {
    width: 15.4px;
    height: 15.4px;
  }
  .filterButtonDiv p {
    font-size: 16px;
    white-space: nowrap;
  }
  .filterForMobileVehicleInventory {
    position: absolute;
    right: 0px;
    width: 80px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background-color: #a1a3ef;
    height: 40px;
    margin-top: 100px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    padding: 12px 10px;
  }
  .filterForMobileVehicleInventory h6 {
    font-size: 13px;
  }
  .filterForMobileVehicleInventory img {
    height: 7px;
    width: 10px;
    margin-top: 7px;
  }
  .filterAddCOurse1Mobile {
    display: initial;
    position: relative;
    left: -20px;
  }
  .vehicleInventorySearchMobile {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: #c0c0c5;
    -ms-flex: 0 0 85%;
    flex: 0 0 85%;
    max-width: 85%;
  }
}

.LinearGradientAccordion {
    background: linear-gradient(90deg, #A1A3EF 0%, #5456C7 100%);
    color: #FFFFFF !important; /* Ensures the text color is white */
  }
  
  .LinearGradientAccordion .MuiAccordionSummary-root,
  .LinearGradientAccordion .MuiAccordionSummary-content {
    color: #FFFFFF !important; /* Ensures the text color in the accordion summary is white */
  }
  
  .LinearGradientAccordion .MuiAccordionDetails-root {
    color: #FFFFFF; /* Ensures the text color in the accordion details is white */
  }
  
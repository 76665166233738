.message__date-header p {
    /* background-color: #888; */
    width: 20rem;
    padding: 10px 0px 10px 0px;
    background-color: #f5f6fa;
    border-radius: 20px;
}

.message__date-header {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #888;
    margin-bottom: 5px;
    margin-top: 10px;
    /* padding: 2rem; */
}

.message__container {
    width: 100%;
    height: 60vh;
    background-color: #fff;
    padding: 20px;
    overflow-y: scroll;
}

.message__container > * {
    margin-bottom: 10px;
    /* padding: 10px; */
}

.message__recipient_ {
    display: flex;
    flex-direction: column;
}
.message__recipient {
    align-self: flex-start;
    background-color: #f5ccc2;
    width: 400px;
    padding: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    font-size: 15px;
    word-wrap: break-word;
}

.message__sender {
    width: 400px;
    align-self: flex-end;
    background-color: rgb(194, 243, 194);
    border-top-left-radius: 10px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 10px;
    font-size: 15px;
    word-wrap: break-word;
}
.message__chats > p {
    font-size: 13px;
}
.message__chats {
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
}

@media screen and (max-width: 768px) {
    .message__sender {
        width: 300px; /* Set width to 300px for screens less than 768px */
    }
}
/* chat end */

.circular {
    position: relative;
    border-radius: 50%;
    background: linear-gradient(160deg, #170654, #410E84);
}

.progress-circle {
   
    width: 100%;
    height: 100%;
    border-radius: 50%;
}